<template>
  <div style="width: 100%">
    <v-col cols="12" class="pb-0">
      <label style="font-size: 16px">Câu hỏi</label>
      <v-textarea
        placeholder="Nhập nội dung"
        class="pt-2 pl-2"
        rows="4"
        auto-grow
        no-resize
        solo
        dense
        v-model.trim="optionAnswerSentenceStressed.correct"
        ref="refSentenceStressed"
      ></v-textarea>
    </v-col>
    <v-col cols="12" style="margin-top: -15px" class="pt-0">
      <v-btn
        class="btn-add-file"
        depressed
        large
        @click="addCorrectSentenceStressed"
      >
        <v-icon left>mdi-plus</v-icon> Stress
      </v-btn>
    </v-col>
    <v-col cols="12" class="d-flex align-center">
      <label style="font-size: 16px">File mẫu:</label>
      <b-form-file
        v-model="sample_file"
        style="width: 50%; margin-left: 10px"
        @change="onChange"
        placeholder="Chọn tệp tin hoặc cancel để xóa"
        accept=".mp3"
        ref="audioSample"
      ></b-form-file>
    </v-col>
    <v-col v-if="sample_data !== null" class="pt-0 pb-0" cols="12">
      <vuetify-audio :file="sample_data" color="success"></vuetify-audio>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "SentenceStressed",
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
  },
  props: {
    option: {
      type: Object,
      default() {
        return {
          correct: "",
          text: "",
        };
      },
    },
    correct: {},
    url_file: String,
    file: File,
  },
  data() {
    return {};
  },
  computed: {
    optionAnswerSentenceStressed: {
      get() {
        return this.option;
      },
      set(value) {
        this.$emit("setOptionAnswer", value);
      },
    },
    correctAnswer: {
      get() {
        return this.correct;
      },
      set(value) {
        this.$emit("setCorrectAnswer", value);
      },
    },
    sample_data: {
      get() {
        return this.url_file;
      },
      set(value) {
        this.$emit("setSampleData", value);
      },
    },
    sample_file: {
      get() {
        return this.file;
      },
      set(value) {
        this.$emit("setSampleFile", value);
      },
    },
  },
  watch: {
    sample_data(val) {
      if (val == null) {
        this.$refs.audioSample.$el.childNodes[1].childNodes[0].textContent =
          "Chọn tệp tin hoặc cancel để xóa";
      }
    },
  },
  mounted() {
    if (this.sample_data == null) {
      this.$refs.audioSample.$el.childNodes[1].childNodes[0].textContent =
        "Chọn tệp tin hoặc cancel để xóa";
    }
  },
  methods: {
    addCorrectSentenceStressed() {
      let insertFirst = "{{";
      let insertLast = "}}";
      let vm = this;
      // let tArea = this.refShortAnswer;
      let textarea = this.$refs.refSentenceStressed;
      let tArea = textarea.$el.children[0].children[0].children[0].children[0];
      // filter:
      if (0 === cursorPos) {
        return;
      }

      // get cursor's position:
      let startPos = tArea.selectionStart,
        endPos = tArea.selectionEnd,
        cursorPos = startPos,
        tmpStr = tArea.value;
      // insert:
      if (endPos === 0) {
        vm.optionAnswerSentenceStressed.correct +=
          " " + insertFirst + insertLast;
      } else {
        vm.optionAnswerSentenceStressed.correct =
          tmpStr.substring(0, startPos) +
          insertFirst +
          insertLast +
          tmpStr.substring(endPos, tmpStr.length);
      }
      // move cursor:
      setTimeout(() => {
        tArea.focus();
        tArea.selectionStart = startPos + 2;
        tArea.selectionEnd = startPos + 2;
      }, 10);
    },

    onChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.sample_data = null;
        vm.sample_file = null;
        return;
      }
      vm.sample_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.sample_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="css" scoped>
.btn-add-file {
  background-color: #05cdff !important;
  color: white !important;
}
</style>
